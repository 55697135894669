@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap);
@font-face {
  font-family: 'Noe display';
  font-style: normal;
  font-weight: 900;
  src: url("/static/media/Noe Display Black.ab2cd0bc.otf") format('opentype');
}
@font-face {
  font-family: 'Noe display';
  font-style: italic;
  font-weight: 900;
  src: url("/static/media/Noe Display Black Italic.82a64fd4.otf")
    format('opentype');
}
@font-face {
  font-family: 'Noe display';
  font-style: normal;
  font-weight: 700;
  src: url("/static/media/Noe Display Bold.06bb722a.otf") format('opentype');
}
@font-face {
  font-family: 'Noe display';
  font-style: italic;
  font-weight: 700;
  src: url("/static/media/Noe Display Bold Italic.98a51298.otf")
    format('opentype');
}
@font-face {
  font-family: 'Noe display';
  font-style: normal;
  font-weight: 500;
  src: url("/static/media/Noe Display Medium.42fd7e49.otf") format('opentype');
}
@font-face {
  font-family: 'Noe display';
  font-style: italic;
  font-weight: 500;
  src: url("/static/media/Noe Display Medium Italic.2e65fc59.otf")
    format('opentype');
}
@font-face {
  font-family: 'Noe display';
  font-style: normal;
  font-weight: 400;
  src: url("/static/media/Noe Display Regular.3445b374.otf")
    format('opentype');
}
@font-face {
  font-family: 'Noe display';
  font-style: italic;
  font-weight: 400;
  src: url("/static/media/Noe Display Regular Italic.8f337b54.otf")
    format('opentype');
}

