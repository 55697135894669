@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap');
@font-face {
  font-family: 'Noe display';
  font-style: normal;
  font-weight: 900;
  src: url('assets/fonts/Noe Display/Noe Display Black.otf') format('opentype');
}
@font-face {
  font-family: 'Noe display';
  font-style: italic;
  font-weight: 900;
  src: url('assets/fonts/Noe Display/Noe Display Black Italic.otf')
    format('opentype');
}
@font-face {
  font-family: 'Noe display';
  font-style: normal;
  font-weight: 700;
  src: url('assets/fonts/Noe Display/Noe Display Bold.otf') format('opentype');
}
@font-face {
  font-family: 'Noe display';
  font-style: italic;
  font-weight: 700;
  src: url('assets/fonts/Noe Display/Noe Display Bold Italic.otf')
    format('opentype');
}
@font-face {
  font-family: 'Noe display';
  font-style: normal;
  font-weight: 500;
  src: url('assets/fonts/Noe Display/Noe Display Medium.otf') format('opentype');
}
@font-face {
  font-family: 'Noe display';
  font-style: italic;
  font-weight: 500;
  src: url('assets/fonts/Noe Display/Noe Display Medium Italic.otf')
    format('opentype');
}
@font-face {
  font-family: 'Noe display';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/Noe Display/Noe Display Regular.otf')
    format('opentype');
}
@font-face {
  font-family: 'Noe display';
  font-style: italic;
  font-weight: 400;
  src: url('assets/fonts/Noe Display/Noe Display Regular Italic.otf')
    format('opentype');
}
